import React from 'react'
import Layout from '../layout/basic.js'

import '../css/overview/style.scss'

const OverviewPage = () => (
  <Layout currentName="overview">
    <section className="overview">

      <article className="hero header-hero has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">会社概要</h1>
            <h2 className="subtitle">OVERVIEW</h2>
          </div>
        </div>
      </article>

      <article className="main-contents has-text-lefted">
        <div className="container columns is-multiline">
          <div className="column is-6 is-offset-3 is-mobile-10 is-mobile-offset-mobile-1">
            <dl className="columns is-multiline is-mobile">
              <dt className="column is-12">社名</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>株式会社gleanbean</p>
                      <p>（gleanbean&nbsp;Co.,&nbsp;Ltd.）</p>
                    </div>
                  </div>
                </div>
              </dd>

              <dt className="column is-12">URL</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>https://gleanbean.co.jp</p>
                    </div>
                  </div>
                </div>
              </dd>

              <dt className="column is-12">本店所在地</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>神奈川県平塚市</p>
                    </div>
                  </div>
                </div>
              </dd>

              <dt className="column is-12">代表取締役</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>稲村&nbsp;章彦</p>
                    </div>
                  </div>
                </div>
              </dd>

              <dt className="column is-12">事業内容</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>ＩＴシステムの企画、設計、開発、販売及びそれらの活用に関するコンサルティング業務。</p>
                    </div>
                  </div>
                </div>
              </dd>

              <dt className="column is-12">資本金</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>2,500千円</p>
                    </div>
                  </div>
                </div>
              </dd>

              <dt className="column is-12">設立</dt>
              <dd className="column is-11 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <p>2019年&nbsp;4月</p>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </article>

    </section>
  </Layout>
);
export default OverviewPage;
